<template>
    <section>
        <el-table :data="bulkUpdateData" border class="mb-1" max-height="250">
            <el-table-column label="Field">
                <template slot-scope="scope">
                    <el-select id="fields" v-model="scope.row.field" clearable
                        @change="fetchValuesForSelectField(scope.row.field)" filterable>
                        <el-option-group v-for="group in bulkUpdateFields" :key="group.label" :label="group.label">
                            <el-option v-for="field in group.options" :key="field.key" :label="field.label" :value="field._id +
                                '#' +
                                field.input_type +
                                '#' +
                                field.template_id +
                                '#' +
                                field.key
                                " :disabled="isFieldDisabled(field, scope.$index)">
                            </el-option>
                        </el-option-group>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="Value">
                <template slot-scope="scope">
                    <el-select :multiple="scope.row.field.split('#')[1] == 'MULTI_SELECT'" :loading="optionsLoading"
                        loading-text="Loading..." v-model="scope.row.value" v-if="scope.row.field" filterable>
                        <el-option v-for="(option, index) in bulkUpdateFieldValuesMapping[
                            scope.row.field
                        ]" :label="option.label" :value="option.value" :key="index">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column width="50" align="center">
                <template slot="header">
                    <i class="el-icon-plus" @click="addBulkUpdateRow"></i>
                </template>
                <template slot-scope="scope">
                    <i :disabled="bulkUpdateData.length == 1" class="el-icon-minus"
                        @click="removeBulkUpdateRow(scope.$index)"></i>
                </template>
            </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
            <button class="btn btn btn-outline-danger btn-sm m-lr-1" type="button" @click="closeBulkUpdateDialogue">
                <i class="fa fa-close"></i> Cancel</button>&nbsp;&nbsp;
            <button class="btn btn btn-outline-success btn-sm m-lr-1" type="button" @click="bulkUpdate"
                id="prepareImportEntityNext">
                <i class="fa fa-sign-out"></i> Update
            </button>
        </div>
    </section>
</template>
<script>
export default {

    data() {
        return {
            // bulkUpdateData: [],
            // bulkUpdateFields: [],
            optionsLoading: false,
            // bulkUpdateFieldValuesMapping: {},
        }
    },
    props:{
        bulkUpdateData:{
            type:Array,
            default:() => [] 
        },
        bulkUpdateFields:{
            type:Array,
            default:() => [] 
        },
        bulkUpdateFieldValuesMapping:{
            type:Object,
            default: () => ({})
        }

    },
    computed: {
        isFieldDisabled(field, index) {
            return this.bulkUpdateData.filter(
                (dt, i) => i !== index && dt.field.split("#")[0] == field._id
            ).length
                ? true
                : false;
        },
    },
    method: {
        addBulkUpdateRow() {
            this.bulkUpdateData.push({
                field: "",
                value: "",
            });
        },
        removeBulkUpdateRow(index) {
            this.bulkUpdateData.splice(index, 1);
        },
        async fetchValuesForSelectField(updateField) {
            this.optionsLoading = true;
            this.bulkUpdateFieldValuesMapping[updateField] = [];
            let field = this.selectAndEntityFields.find(
                (e) => e._id == updateField.split("#")[0]
            );
            if (
                field?.input_type == "SELECT" ||
                field?.input_type == "MULTI_SELECT"
            ) {
                let options = field.options;
                if (field?.is_global_variable && field?.global_variable_id) {
                    let globalVariable = await this.setGlobalVariableData(
                        field.global_variable_id
                    );
                    options = globalVariable?.options;
                }
                this.bulkUpdateFieldValuesMapping[updateField] = options.map(
                    (option) => {
                        return {
                            label: option,
                            value: option,
                        };
                    }
                );
            } else if (field?.input_type == "ENTITY") {
                if (
                    !field.primary_fields.length &&
                    !this.entityMapping[field.entity_id]
                ) {
                    //   this.entityMapping[field.entity_id] = await fetchEntityById(
                    //     field.entity_id
                    //   );
                }
                if (!this.entityDataMapping[field.entity_id]) {
                    //   let response = await postAPICall(
                    //     "POST",
                    //     "/entities-data/entity/data",
                    //     { entity_id: field.entity_id }
                    //   );
                    //   if (response?.data) {
                    //     this.entityDataMapping[field.entity_id] = [...response.data];
                    //   }
                }
                this.bulkUpdateFieldValuesMapping[updateField] = (
                    this.entityDataMapping[field.entity_id] || []
                ).map((record) => {
                    let label;
                    if (field.primary_fields.length) {
                        label = "";
                        (field.primary_fields || []).map((pf) => {
                            let [t, k] = pf.split("#");
                            label =
                                label + (label == "" ? "" : " - ") + record.entityData[t][k];
                        });
                        return {
                            label: label,
                            value: label + "#" + record._id,
                        };
                    } else {
                        label =
                            record.entityData[
                            this.entityMapping[field.entity_id].primaryFields[0].template_id
                            ][this.entityMapping[field.entity_id].primaryFields[0].key];
                        return {
                            label: label,
                            value: label + "#" + record._id,
                        };
                    }
                });
            } else if (field?.input_type == "CHECKBOX") {
                this.bulkUpdateFieldValuesMapping[updateField] = [
                    { label: "Checked", value: true },
                    { label: "Unchecked", value: false },
                ];
            }
            this.optionsLoading = false;
        },
        async bulkUpdate() {
            let selectedIds = this.selectedRowsIds.map((row) => {
                return row._id;
            });

            let params = {
                data: this.bulkUpdateData,
                ids: selectedIds,
                entity_id: this.entity_id,
            };
            await this.$store.dispatch("entities/bulkUpdateEntityData", params);
            this.bulkUpdateDialgVisible = false;
            if (this.getBulkUpdateStatus) {
                this.fetchEntitiesDataForTable();
                this.selectedRowsIds = [];
                this.$notify.success({
                    title: "Success",
                    message: "Your data updated successfully",
                });
            } else {
                this.$notify.error({
                    title: "Error",
                    message: "Error in updating  the data",
                });
            }
            this.bulkUpdateData = [
                {
                    field: "",
                    value: "",
                },
            ];
            this.bulkUpdateFields = [];
        },
    }

}
</script>